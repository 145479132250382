<template>
<div>
    Login with google <br />
    <button @click="login" class="button">Click to sign in</button>
</div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: 'Login',
  methods: {
    ...mapActions([
      'login'
    ]),
  },
  data() {
    return {
      
    }
  }
}
</script>